import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup: Snatch Balance 4-4-4-4 (light)`}</p>
    <p>{`Full Snatch  4-4-4-4 to a 4RM`}</p>
    <p>{`then,`}</p>
    <p>{`11:00 AMRAP:`}</p>
    <p>{`5-Power Snatch (135/95)`}</p>
    <p>{`7-Ring Dips`}</p>
    <p>{`9-Burpee Box Jump Overs (24/20″)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      